.sources {
  max-height: 0;
  overflow: hidden;
  transition: max-height 2s ease-out;
}

.sources.loaded {
  max-height: 200px;
}

.error-text {
  opacity: 1;
  font-size: 16px;
  line-height: 24px;
}

.explanation {
  cursor: pointer;
  opacity: 1;
  font-size: 16px;
  line-height: 24px;
}

.explanation.hovered {
  opacity: 1;
  color: rgba(212, 107, 8, 1);
}

.isHovering {
  opacity: 0.5;
}

.explanation.active {
  background-color: rgba(226, 232, 240, 1);
}

.explanation.inactive {
  background-color: initial;
}
